// variables and mixins
@import "./lbd/variables";
@import "./lbd/mixins";
@import "fonts";
@import './scheduling.scss';
@import './notifications-dock.scss';
@import './map.scss';
@import './tables.scss';
@import './charts.scss';
@import './custom-form.scss';
@import './theme-customization.scss';
@import './wrappers.scss';
@import './stamped.scss';
@import './profile.scss';
@import './react-calendar.scss';
@import './jsonFormWidgets.scss';

* {
  // outline: 2px solid limegreen!important;
}


/*for chrome,safari,opera,edge*/
/* width */
*::-webkit-scrollbar {
  width: 6px;
  height: 8px;
  margin: 0 15px;
}

/* Track */
*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #dbe5ed;
  border-radius: 10px;
}

/* Handle */
*::-webkit-scrollbar-thumb {
  background-color: #AAB7CF;
  border-radius: 10px;
}


body {
  font-family: "Open Sans", "Roboto", "Helvetica Neue", sans-serif;
  overscroll-behavior-x: none;
}

.landing_nav {
  height: 100vh;
  width: 100vw;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  a {
    margin: 0 10px;
    font-size: 20px;

    &:hover {
      text-decoration: underline;
    }
  }
}

.list-errors {
  padding: 14px;
  border-radius: 4px;
  border: 2px solid #F2105A;
  display: flex;
  flex-direction: column;
  color: #F2105A;
  margin: 10px auto;
  width: 100%;

  &__item {
    &:first-letter {
      text-transform: uppercase;
    }

    color: #F2105A;
  }
}

/*
.ReactTable{
  border: none;
  *{
    border: none!important;
  }
  .rt-thead{
    &.-header{
      box-shadow: none;
    }
    .rt-tr{
      background-color: $default-color-light;
    }
    .rt-resizable-header-content{
      color: $default-color;
      font-weight: 900;
      text-align: center;
      font-size: 14px;
    }
  }
  .rt-tbody{
    overflow: hidden;
    .rt-tr-group{
      margin-top: 5px;
      max-height: 40px;
    }
    .rt-tr{
      max-height: 40px;
      &.-even{
        background-color: $default-color-light;
        &:hover{
          background-color: $default-color-light!important;;
        }
      }
      &.-odd{
        background-color: #fff;
        &:hover{
          background-color: #fff!important;
        }
      }
    }
    .rt-td{
      text-align: center;
      color: $light-gray;
      font-size: 12px;
      font-weight: 600;
    }
  }
  .pagination-bottom{
    .-pagination{
      box-shadow: none;
    }
  }
}
*/
.ReactTable {
  border: none;

  .rt-thead {
    &.-header {
      box-shadow: none;
    }
  }

  .rt-tbody {
    overflow: hidden;

    .rt-tr-group {}

    .rt-tr {
      max-height: 40px;
    }

    .rt-td {}
  }
}


.Table-pag {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  &__prevPageWrapper {}

  &__nextPageWrapper {}

  &__page-nav {
    padding: 0;
    display: flex;
    align-items: center;
    background: transparent;
    outline: none !important;
    border: none !important;

    i {
      font-size: 30px;
      font-weight: 900;
      color: $default-color;
      transition: 0.3s ease-in-out all;

      &:hover {
        text-shadow: 1px 1px 10px $default-color;
      }
    }

    &_disabled {
      opacity: 0.5;

      i {
        &:hover {
          text-shadow: none;
        }
      }
    }
  }

  &__pageButton {
    min-height: 28px;
    min-width: 28px;
    border-radius: 15px;
    outline: none !important;
    border: none !important;
    font-size: 14px;
    font-weight: 600;
    color: $default-color;
    transition: .3s ease-in-out all;

    &:hover {
      background-color: $default-color-light;
    }

    &_active {
      background-color: $default-color !important;
      color: #ffffff !important;
    }
  }

  &__visiblePagesWrapper {
    display: inline-flex;
    margin-right: 10px;
    margin-left: 10px;
    padding: 0;
    border-radius: 5px;
    gap: 15px;

    button {
      padding: 0;
      margin-right: 5px;

      &:last-child {
        margin-right: 0;
      }
    }

    span {
      height: 40px;
      display: flex;
      align-items: flex-end;
    }
  }

  &__mobile {
    display: none;
  }
}


.table_pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  color: #2550AC;
  border: 1px solid #dee2e6;
  border-radius: 4px;

  &__active {
    color: white;
    border: 1px solid #2550AC;
    background-color: #2550AC;
  }

  &__disabled {
    color: #dee2e6;
    cursor: no-drop;
  }
}

@media screen and (max-width: 500px) {
  .Table-pag__visiblePagesWrapper {
    display: none;
  }

  .Table-pag__visiblePagesWrapper__mobile {
    display: block;
  }

  .array-item-add {
    width: 20%;

    // margin-left: 40%;
    button {
      background-image: url("../../assets/img/new_design/jsx_adminnavbar_svg/add_icon.svg") !important;
      background-position: center !important;
      background-repeat: no-repeat !important;
      padding: 25% !important;
      width: 50px !important;

      i {
        display: none !important;
      }
    }
  }

  .array-item-remove {
    background-image: url("../../assets/img/minus_icon.svg") !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    padding: 15% !important;
    width: 50px !important;

    i {
      display: none !important;
    }
  }

}

@media screen and (min-width: 500px) and (max-width:900px) {
  .array-item-add {
    width: 20%;

    // margin-left: 40%;
    button {
      background-image: url("../../assets/img/new_design/jsx_adminnavbar_svg/add_icon.svg") !important;
      background-position: center !important;
      background-repeat: no-repeat !important;
      padding: 15% !important;
      width: 50px !important;

      i {
        display: none !important;
      }
    }
  }

  .array-item-remove {
    background-image: url("../../assets/img/minus_icon.svg") !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    padding: 10% !important;
    width: 50px !important;

    i {
      display: none !important;
    }
  }

}



@media screen and (min-width: 501px) {
  .Table-pag__visiblePagesWrapper__mobile {
    display: none;
  }
}

.actions-center {
  padding-top: 0px;
  margin-top: -2px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;

  &__center {
    justify-content: center !important;
  }

  .btn-simple {
    padding: 3px 4px 3px 4px;
    font-size: 18px;
    background: #447df7;
    border-radius: 5px;
    color: white;
    margin-left: 5px;

    &:hover {
      background: #2769f6;
      color: white;
    }
  }

  .no-na-btn {
    margin: 0px 5px;
  }

  .btn-green {
    padding: 3px 4px 3px 4px;
    background: green;
    border-radius: 5px;
    color: white;
    margin-left: 5px;
    border: none !important;

    &:hover {
      background: green;
      color: white;
    }
  }
}

.actions-center-signdoc {
  padding-top: 0px;
  margin-top: -2px;
  text-align: right !important;
  display: flex;
  justify-content: center;

  .btn-simple {
    padding: 3px 4px 3px 4px;
    font-size: 18px;
    background: #447df7;
    border-radius: 5px;
    color: white;
    margin-left: 5px;

    &:hover {
      background: #2769f6;
      color: white;
    }
  }
}

.list-main-column {
  cursor: pointer;
  font-weight: 600 !important;
  color: #2550AC;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
    color: #2769f6;
  }
}

.status-box {
  border-radius: 10px;
  font-size: 10px;
  font-weight: bold;
  text-align: center;
  color: #ffffff;
  display: inline-block;
  padding: 2px 10px;
  min-width: 70px;

  &_active {
    background-color: $green-color-top;
    color: $green-color-bottom;
  }

  &_inactive {
    background-color: $red-color-top;
    color: $red-color-bottom;
  }

  &_draft {
    background-color: $default-color-top;
    color: $default-color-bottom;
  }
}

.header-meta {
  margin-bottom: 20px;

  button {
    font-weight: 600;
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.radio-widget {
  display: flex;
  flex-wrap: wrap;
  min-height: 40px;
  align-items: center;

  .radio {
    margin: 5px;
  }

  &.vertical {
    display: block;
  }
}

.form-chooser {
  outline:aqua solid 2px
}

.array-item-add {
  width: 20%;
  // margin-left: 40%;

  button {
    background-image: url("../../assets/img/new_design/jsx_adminnavbar_svg/add_icon.svg");
    background-position: center;
    background-repeat: no-repeat;
    padding: 6%;
    width: 50px;

    i {
      display: none;
    }
  }
}

.array-item-remove {
  background-image: url("../../assets/img/minus_icon.svg");
  background-position: center;
  background-repeat: no-repeat;
  padding: 5%;
  width: 50px;

  i {
    display: none;
  }
}

.array-item-move-up {
  background-image: url("../../assets/img/arrow-up.svg");
  background-position: center;
  background-repeat: no-repeat;
  padding: 6%;
  width: 50px;

  i {
    display: none;
  }
}

.array-item-move-down {
  background-image: url("../../assets/img/arrow-down.svg");
  background-position: center;
  background-repeat: no-repeat;
  padding: 6%;
  width: 50px;

  i {
    display: none;
  }
}


.list-filters {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  border-radius: 5px;
  border: 1px solid #d0d0d0;
  margin: 15px 0 27px 0;

  button {
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }
  }

  &__item {
    display: flex;
    align-items: center;
    padding: 10px;

    &_to-right {
      margin-left: auto;
    }

    &_grow {
      flex-grow: 1;
    }

    .input-block {
      padding-top: 0;
      width: 100% !important;
    }
  }

  .managers-widget {
    width: 150px;
  }
}

.member_status {
  &__summary {
    div>div {
      display: flex;
      justify-content: center;
      align-items: center;
      // min-width: 170px;
      // height: 40px;
      font-size: 14px;
      border-radius: 14px;
      padding: 10px;
    }

    div.active {
      box-shadow: 1px 5px 5px -3px rgba(0, 0, 0, 0.7);
      cursor: not-allowed;
    }

    div.highlighted {
      background-color: #EFF1E4;
      color: #447df7;
    }

    div.highlightedred {
      background-color: #ffe6e6;
      color: #FF0000;
    }

    div.highlightedgreen {
      background-color: #16C51D;
      color: #FFFFFF;
    }
  }

  &__header {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;

    button {
      margin: 5px 10px;
    }
  }
}

.primary-page {
  padding: 0;
  box-sizing: border-box;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 30px;
  }

  &__title {
    margin: 0;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    letter-spacing: 0.4px;
    color: #2550AC;

    &__draft {
      color: $red-color-bottom;
      font-size: 12px;
    }
  }
}

.project-statuses {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  min-height: 40px;

  .radio {
    padding: 5px;
    margin: 0;
  }
}

.checkbox label,
.radio label {
  user-select: none;
}

.entity-attachment .btn-doc-upload {
  margin-top: -4.3em !important;
  margin-left: 50.5em !important;
}

.uploads {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 0;
  width: 100%;
  user-select: none;
  flex-wrap: wrap-reverse;

  &-item {
    display: block;
    padding: 10px;
    text-align: center;
    width: 100px;
    margin: 0;

    &__title {
      display: block;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: #447df7;
    }

    &__img {
      max-width: 60px;
      width: 100%;
      cursor: pointer;
    }

    &_single {
      width: 100%;
      max-width: 100%;
      margin: auto;
    }

    &__file {
      position: relative;
      display: inline-block;
    }

    &__delete {
      position: absolute;
      top: 0;
      right: 0;
      font-size: 18px;
      font-weight: 900;
      outline: none !important;
      border: none;
      transition: 0.3s ease-in-out;
      cursor: pointer;
      color: rgba(255, 30, 80, 0);
      background-color: transparent;

      &:hover {
        color: rgba(255, 30, 80, 0.5);
      }
    }

    &__icon {
      cursor: pointer;
      font-size: 60px;
      color: #447df7;
    }

    &:hover {
      .uploads-item__delete {
        color: rgba(255, 30, 80, 1);
        background-color: #ffffff;
      }
    }
  }

  &__add-button {
    width: 60px;
    height: 60px;
    border: 2px solid #447df7;
    text-align: center;
    border-radius: 50%;
    padding: 5px;
    outline: none !important;
    opacity: 0.8;
    transition: 0.3s ease-in-out;
    background-color: transparent;

    i {
      line-height: 53px;
      transition: 0.3s ease-in-out;
      font-size: 45px;
      font-weight: 600;
      color: #447df7;
    }

    &:hover {
      background-color: #447df7;

      i {
        color: #ffffff;
      }
    }
  }

  .input-area {
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &_with-groups {
    justify-content: flex-start;

    .input-area {
      margin-left: 7px;
      margin-right: 7px;

      &__content {
        padding: 10px 0px !important;
      }
    }
  }

  &__image_container {
    padding: 5px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
  }

  &__image {
    width: 140px;
    height: 135px;
    border-radius: 50%;
    background: $default-color-top;
    object-fit: cover;
  }

  &__camera_icon {
    margin-top: 80px;
    margin-left: -40px;
  }

  &__dashed_image_container {
    width: 217px;
    height: 117px;
    display: flex;
    justify-content: center;
    align-items: center;
    // border: 1px dashed #4066B6;
    border-radius: 24px;
    // padding: 35px 10px;
  }

  &__dashed_default_image {
    width: 40px;
    height: 40px;
    // object-fit: cover; 
  }

  &__dashed_image {
    width: 100%;
    height: 100%;
    border-radius: 24px;
    border: none !important;
  }
}

.accordion {

  // background-color: #fff;  // commented because of new design
  &__button {
    padding: 15px 70px 15px 19px; // modified -> 22px 70px 22px 19px
    background-color: #F9FAF5; // modified  -> #fff
    border-bottom: 1px solid #E7E7E7;
    border-radius: 10px; // added because of new design
    transition: 0.3s ease-in-out;
    position: relative;
    user-select: none;
    outline: none !important;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.404255px;
    color: #373737;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    &:hover {
      background-color: #E7E7E7;
    }

    &[aria-expanded='true'] {
      .accordion__icon {
        transform: rotate(180deg);
      }

      background-color: #2550AC !important;
      color: white !important;
    }
  }

  &__icon {
    max-width: 25px;
    min-width: 25px;
    max-height: 25px;
    min-height: 25px;
    position: absolute;
    // border: 1px solid rgba(0, 0, 0, 0.18); // commented because of new design
    // border-radius: 50%; // commented because of new design
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    transition: 0.3s ease-in-out;
    right: 45px;
    bottom: 0;
    margin: auto 0;

    svg {
      top: 1px;
      position: relative;
      max-width: 12px;
    }
  }

  &__panel {
    animation: fadein 0.35s ease-in;
    // background: #FFFFFF; // commented because of new design
    padding: 30px;
    // border-bottom: 1px solid #E7E7E7; // commented because of new design
  }
}

.timelog_modal {
  .accordion__panel {
    padding: 0 !important;
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.DateRangePicker {
  display: flex;
  align-items: flex-start;
  justify-content: center;

  &__CalendarSelection {
    background-color: #447df7;
    border-color: #447df7;
  }

  &__Date {
    &--is-hightlighed {}
  }

  &__CalendarHighlight {
    &--single {
      border-color: #447df7;
    }
  }

  &__PaginationArrow {
    position: static;
    min-width: 30px;
    min-height: 30px;
    max-width: 30px;
    align-self: center;
    max-height: 30px;
    border-radius: 50%;

    &:hover {
      background-color: #447df7;

      .DateRangePicker__PaginationArrowIcon {
        -webkit-filter: invert(100%);
      }
    }
  }

  &__PaginationArrowIcon {
    position: static;
    background-image: url('../../assets/img/arrow.svg');
    background-repeat: no-repeat;
    -webkit-background-size: 20px;
    background-size: 20px;
    background-position: center;
    width: 30px;
    height: 30px;
    border: none;
    margin: 0;
    display: inline-block;

    &--next {
      transform: rotate(-90deg);
    }

    &--previous {
      transform: rotate(90deg);
    }
  }
}

.did-not-work {
  padding: 15px;
  border: 2px solid #447df7;
  border-radius: 15px;
  margin-bottom: 20px;

  &__radios {
    display: flex;
    align-items: center;

    .radio {
      margin: 0;
      padding: 5px;
    }
  }

  &__buttons {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;

    button {
      &:last-child {
        margin-left: 20px;
      }
    }
  }

  &__radio-title {
    margin-right: 10px;
  }
}

.timelog-grid {
  margin-top: 15px;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  background-color: #F1F5F8;

  &__title {
    width: 100%;
    text-align: left;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 18px;
    font-family: 'Exo 2', sans-serif;
    line-height: 22px;
    letter-spacing: 0.40px;
    color: #2550AC; // added ->new design
  }

  &__row {
    width: 100%;
    background-color: #F1F5F8;
    display: flex;
    align-items: stretch;
    border-bottom: 1px solid rgba(0, 0, 0, 0.09);

    &:last-child {
      border-bottom: none;
    }

    .timelog-grid__item:last-child {
      border-right: none;
    }
  }

  &__icon {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin-right: 11px;
  }

  &__input-text {
    margin-left: auto;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.75px;
  }

  &__text {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 14px; // modified -> 16px
    line-height: 22px;
    letter-spacing: 0.75px;
    padding-right: 10px;
    width: 100px;

    &-capitalize {
      text-transform: capitalize;
    }
  }

  .big-input {
    text-align: center;
  }

  &__input {
    min-width: 45px;
    margin-left: auto;
    width: auto;
    color: #373737;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.75px;

    &[readonly] {
      pointer-events: none;
      color: #373737;
    }

    &:invalid {
      border-color: #FF1E50;
      color: #FF1E50;
    }

    &:invalid+.timelog-grid__info {
      display: block;
    }
  }

  .time-select {
    margin-left: auto;

    &__control {
      background-color: transparent;
    }

    &__single-value {
      color: #373737;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: 0.75px;
    }
  }

  &__info {
    color: #FF1E50;
    width: 100%;
    display: none;
  }

  input[type="time"]::-webkit-inner-spin-button,
  input[type="time"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="time"]::-webkit-clear-button {
    display: none;
  }
}

.timelog-action-block {
  display: flex;
  align-items: center;
  padding: 15px;
  border: 2px solid #447df7;
  border-radius: 15px;
  margin-bottom: 10px;

  &__label {
    flex-grow: 1;
    padding-right: 10px;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.75px;
  }

  &__buttons {

    // display: flex;
    // align-items: center;
    button {
      margin: 0 5px;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.flex-card {
  border-radius: 20px;
  background-color: #ffffff;
  margin-bottom: 30px;
  border: 1px solid rgba(0, 0, 0, 0.125);
  overflow: hidden;
  padding: 10px;
  min-height: 7rem;
  min-width: 14.5rem;
  max-width: 18rem;
  height: 7.5rem;
  max-height: 18rem;
  display: flex;
  flex-direction: column;

  &__header {
    flex-grow: 1;
  }

  &__header-wrap {
    // padding-bottom: 15px;
    display: flex;
    align-items: center;
  }

  &__header-icon {
    min-width: 40px;

    i {
      font-size: 40px;
      font-weight: 900;
    }
  }

  &__content {}

  &__footer {
    margin-top: auto;
    padding-top: 10px;
    border-top: 1px solid #dddddd;
  }

  &__title {
    font-size: 16px;
    line-height: 1.5;
    font-weight: 600;
    margin: 0;
  }

  &__desc {
    margin-top: 10px;
    color: #a9a9a9;
    font-size: 14px;
    font-weight: 400;
    white-space: nowrap;
    display: block;
    overflow: hidden;
    width: 90%;
    text-overflow: ellipsis;
  }
}

.flex-card-footer-buttons {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: -5px;
  justify-content: space-around;

  button {
    margin: 5px;
    flex-grow: 1;
    padding: 5px 10px;
  }
}

;

.react-datepicker {
  border-color: #447df7 !important;

  &__triangle {
    border-bottom-color: #ffffff !important;

    &:before {
      border-bottom-color: #447df7 !important;
    }
  }

  &__navigation {
    outline: none !important;

    // border: 7px solid transparent;
    // &--previous{
    //   border-right-color: #447df7;
    // }
    // &--next{
    //   border-left-color: #447df7;
    // }
    &-icon::before {
      top: 11px !important;
    }
  }

  &__header {
    background-color: #ffffff;
    padding-bottom: 5px;
    border-bottom: 2px solid #447df7;
  }

  &__current-month {
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 7px;
  }

  &__day-names {}

  &__day-name {
    font-size: 14px;
    width: 30px;
  }

  &__month {}

  &__week {}

  &__day {
    width: 30px;
    line-height: 30px;
    font-size: 14px;

    &--selected {
      background-color: #447df7 !important;
      ;
    }
  }

  &-wrapper {
    // max-width: 300px;
    width: 100%;
  }

  &__input-container {
    width: 100%;
    position: relative;

    &:before {
      content: url("../../assets/img/date-picker-icon.svg");
      display: block;
      width: 21px;
      height: 21px;
      position: absolute;
      left: 10px;
      top: -2px;
      margin: auto 0;
      bottom: 0;
      pointer-events: none;
    }

    input {
      width: 100%;
      background: #FFFFFF;
      border: 1px solid #cccccc;
      box-sizing: border-box;
      border-radius: 5px;
      padding: 15px 13px 15px 57px;
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      letter-spacing: 0.404255px;
      color: #373737;
    }

    input.smaller {
      padding: 8px 13px 11px 50px;
      width: 200px;
    }
  }
}

.register-dist {
  display: flex;
  flex-direction: column;

  &__main-input {
    width: 100%;
    background-color: #F1F5F8;
    display: flex;
    padding: 34px 20px 24px 20px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    border-radius: 20px;

    img {
      width: 50px;
      // padding-bottom: 14px; // commented because of new design
      // border-bottom: 2px solid rgba(0, 0, 0, 0.3); // commented because of new design
      margin-bottom: 1rem;
    }

    &>div {
      display: flex;
      align-items: center;

      span {
        font-weight: 600;
        font-size: 14px;
        line-height: 38px;
        letter-spacing: 0.4px;
        text-transform: uppercase;
        color: #FF1E50;
        // width: 100px; // commented for new design
      }
    }

    input {
      font-weight: 600;
      font-size: 28px;
      line-height: 30px;
      height: auto;
      letter-spacing: 0.4px;
      text-transform: uppercase;
      color: #FF1E50;
      width: 170px;
      border-radius: 10px;
      border: 1px solid #BFD4E5;
      text-align: center;

      &:focus {
        border: none;
      }
    }

    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    input[type="number"]::-webkit-clear-button {
      display: none;
    }
  }

  &__inputs {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }

  &__input {
    width: 50%;
    box-sizing: border-box;

    &:first-child {
      padding-right: 10px;
    }

    &:last-child {
      padding-left: 10px;
    }
  }
}

.input-block {
  display: block;
  width: 100%;
  padding-top: 10px;
  margin-top: -19px;

  &_no-label {
    margin-top: 0;
    padding-top: 0;
  }

  &__label {
    display: inline-block;
    position: relative;
    margin-left: 0px; // changed because of new input design set to 10px if want to revert
    color: #343434 !important; // added because of new design
    font-family: 'Exo 2', sans-serif; // added because of new design
    font-weight: 600 !important; // added because of new design
    max-width: calc(100% - 20px);
    padding: 0 4px;
    font-size: 14px;
    line-height: 19px;
    font-weight: 400;
    letter-spacing: 0.45px;
    z-index: 2;
    color: #818181;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__base-input {
    height: 46px; // modified for new design if want to revert it set to 56px
    border-color: #C4C4C4;
    border-radius: 10px;
    padding: 10px 16px; // added -> new design
    background: #F3F4ED; // added -> new design

    &::placeholder {
      font-weight: 400;
    }

    &:focus {
      border-color: #447df7;
    }

    &_error {
      border-color: #FF1E50;

      &:focus {
        border-color: #FF1E50;
      }
    }

    &_textarea {
      padding-top: 15px;
      resize: none;
      height: 130px !important;

      &_timelog {
        height: 65px !important;
      }
    }
  }

  &__date-picker {
    line-height: 25px !important;
    border-radius: 10px !important;
    padding: 0.375rem 10px 0.375rem 35px !important;

    &:focus {
      border-color: #447df7 !important;
    }

    &_error {
      border-color: #FF1E50 !important;
      ;

      &:focus {
        border-color: #FF1E50 !important;
        ;
      }
    }

    &_bg_grey {
      border: 0 !important;
      background-color: #F9FAF5 !important;
    }
  }

  .react-datepicker-wrapper {
    max-width: 100%;
  }
}

.signature {
  position: relative;
  display: inline-flex;
  background-color: #EEEFE9; // modified because of new design, revert to #ffffff if not needed;
  margin-top: 25px; // modified -> change to 10px if want to revert it
  background-color: transparent;
  width: 100%;

  &__label {
    position: absolute;
    display: inline-block; // added because of new design
    top: -24px;
    color: #343434 !important; // added because of new design
    font-family: 'Exo 2', sans-serif; // added because of new design
    z-index: 5;
    max-width: calc(100% - 20px);
    padding: 0 4px;
    pointer-events: none;
    user-select: none;
    font-size: 14px;
    line-height: 19px;
    font-weight: 600; // changes because of new design set to 400 if want to revert it
    letter-spacing: 0.45px;
    color: #818181;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__bg {
    position: absolute;
    bottom: 50%; // set to 20% if want to revert it
    left: 6%; // set to 10% if want to revert it
    user-select: none;
    right: 10%;
    padding-bottom: 10px;
    pointer-events: none;
    z-index: 2;
  }

  &__panel {
    position: relative;
    z-index: 1;
    border-radius: 10px;
  }

  &__cancel {
    position: absolute;
    outline: none !important;
    border: none;
    top: 0;
    right: 0;
    z-index: 2;
    background: transparent;
    padding: 0;

    i {
      transition: 0.3s ease-in-out;
    }

    &:hover {
      i {
        color: #447df7;
      }
    }
  }

  &_error {
    * {
      color: #FF1E50;
      border-color: #FF1E50;
    }
  }
}

.m-signature-pad {
  user-select: none;
  height: 100%;
  position: relative;

  &--body {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: absolute;
  }

  canvas {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    bottom: 0;
    right: 0;
  }
}

.timelog-array {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  &__left {
    flex-grow: 1;
    width: 50%;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
  }

  &__left-inputs {
    width: 50%;
    flex-grow: 1;
    padding: 0 10px 10px 10px;
    min-width: 300px;
    box-sizing: border-box;
  }

  &__comment {
    width: 100%;
    box-sizing: border-box
  }

  &__attachment {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 300px;
    width: 100%;

    .attachment-box {
      width: 100%;
      min-height: 120px;
      margin-top: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }

    .timelogArrayAttachmentWidget {
      height: auto !important;
      width: 100%;
    }
  }

  &__right {
    flex-grow: 1;
    min-width: 280px;
    width: 50%;
    padding: 0 15px;
    box-sizing: border-box;

    & table {
      margin-top: 0.2rem !important;
    }

    & th {
      text-transform: uppercase !important;
    }
  }

  &__footer {
    padding: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;

    button {
      margin-right: 10px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__input {
    margin-bottom: 20px;
  }

  &__row {
    text-align: center;
    padding: 5px;
    display: flex;
    border-bottom: 1px solid #D8D8D8;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.45px;
    min-height: 43px;
    align-items: center;

    &_head {
      background-color: #F2F2F2;
      border-bottom: none;
      font-weight: 600;
    }

    &_not-found {
      justify-content: center;
    }

    &:last-child {
      border-bottom: none;
    }
  }

  &__col {
    &_center {
      text-align: center;
    }

    &_right {
      text-align: right;
    }
  }

  &__col-action {
    border: none;
    outline: none !important;
    padding: 0;
    transition: .3s ease-in-out;

    &:hover {
      opacity: 0.8;
    }
  }
}

.attachment-box {
  position: relative;
  background-color: #F9FAF5; // modified -> #ffffff
  border: 1px dashed #2550AC;
  border-radius: 10px; // changed because of new design set to 4px if want to revert it.
  color: #565656;
  padding: 8px 12px;
  box-shadow: none;
  margin-top: 10px;
  display: flex;
  min-height: 120px;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  &__label {
    position: absolute;
    display: inline-block; // added because of new design
    top: -35px; // set to -10px if want to revert it
    left: 0px; // modified because of new design set to 10px if want to revert
    font-family: 'Exo 2', sans-serif; // added because of new design
    padding: 0 4px;
    font-size: 14px;
    line-height: 19px;
    font-weight: 600;
    letter-spacing: 0.45px;
    color: #818181;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.attachment-box-align {
  width: 100%;
  text-align: center;
  cursor: pointer;
}

.dynamic-list {
  display: flex;
  flex-wrap: wrap;

  &__top {
    width: 100%;
    text-align: left;
    font-size: 18px;
    font-weight: 600;
    color: #2550AC;
    ;
  }

  &__left {
    flex-grow: 1;
    width: 50%;
    min-width: 300px;
    padding: 10px;
  }

  &__right {
    min-width: 300px;
    flex-grow: 1;
    width: 50%;
    padding: 10px;
  }

  &__buttons {
    padding: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    width: 100%;

    button {
      margin-right: 10px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.timelog-final {
  display: flex;
  flex-direction: column;

  &__item {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  &__item-title {
    font-family: "Open Sans", "Roboto", "Helvetica Neue", sans-serif;
    margin: 0 0 20px 0;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.4px;
    color: #373737;
  }

  &__item-box {
    font-family: "Open Sans", "Roboto", "Helvetica Neue", sans-serif;
    margin: 0 20px 0 0;
    padding: 15px;
    border-radius: 10px;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.4px;
    color: white;

    & img {
      margin-right: 5px;
    }

    & b {
      padding-left: 50px;
    }
  }
}

.vacation-box {
  display: inline-flex;
  background: #FFFFFF;
  border-radius: 4px;
  min-width: 350px;
  width: auto;

  &__item {
    display: flex;
    flex-direction: column;
    padding: 12px;
    border-right: 1px solid rgba(0, 0, 0, 0.08);
    min-width: 100px;
    max-width: 170px;
    min-height: 80px;
    background: #EFF0F9;
    border-radius: 28px;
    border-left: 3px solid #2550AC;

    &:last-child {
      border-right: none;
    }
  }

  &__label {
    font-size: 13px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0.444445px;
    color: #979797;
    white-space: nowrap;
    display: block;
    max-width: calc(100% - 20px);
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__value {
    font-weight: 600;
    font-size: 24px;
    line-height: 33px;
    text-align: center;
    letter-spacing: 0.444445px;
    color: #2550AC;
  }
}

.vacation-panel {
  display: inline-flex;
  flex-wrap: wrap;
  background: #FFFFFF;
  border-radius: 4px;
  min-width: 350px;
  width: auto;

  &__item {
    display: flex;
    flex-direction: column;
    padding: 12px;
    margin: auto;
    align-items: center;
    max-width: 170px;
    min-width: 100px;
    min-height: 80px;
  }

  &__divider {
    border-right: 2px solid rgba(0, 0, 0, .08);
  }

  &__label {
    font-size: 13px;
    line-height: 18px;
    letter-spacing: 0.444445px;
    color: #979797;
    position: relative;
    white-space: nowrap;
    display: block;
    max-width: calc(100% - 20px);
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__gradient_underline {
    border-bottom: 3px solid;
    border-image: linear-gradient(270deg, #FF9213 1.05%, #2550AC 100%) 1;
  }

  &__value {
    font-weight: 600;
    font-size: 24px;
    line-height: 33px;
    letter-spacing: 0.444445px;
    color: #2550AC;
  }

  &__value_small {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.444445px;
    color: #2550AC;
  }
}

@media screen and (max-width: 876px) {
  .list-filters {
    border-radius: 14px;
    justify-content: center;
  }

  .vacation-total {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;

    .vacation-box {
      margin-right: 0;
    }
  }

  .vacation-box {
    min-width: 100%;
    width: 100%;
  }
}

@media screen and (min-width: 950px) {
  .modal-dialog {
    width: 900px;
    margin: 30px auto;
  }

  .modal-dialog.sm {
    width: 500px;
    margin: 20px auto;
  }
}

.status-block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  min-width: 5rem;

  &__fixed-width {
    max-width: 6rem;
    min-width: 2rem !important;
  }

  i {
    font-weight: bold;
    font-size: 16px;
  }
}

.status-active,
.status-auto,
.status-pending {
  cursor: pointer;
  background-color: darkorange;
  color: white;
  height: 30px;
  font-size: 10px;
  padding-top: 0px;
}

.status-block-height35 {
  height: 35px !important;
}

.status-draft,
.status-ongoing {
  cursor: pointer;
  background-color: #dce1dc;
  color: rgb(0, 0, 0);
  height: 30px;
  font-size: 11px;
}

.status-no-click-for-member {
  cursor: default;
  font-size: 10px;
}

.status-approved {
  background-color: green;
  cursor: pointer;
  color: white;
  height: 30px;
  font-size: 8px;
}

.status-approved-validate-timetrack {
  cursor: pointer;
  height: 30px;
  font-size: 8px;
}

.status-rejected {
  cursor: pointer;
  background-color: red;
  color: white;
  height: 30px;
  font-size: 8px;
}

.status-cancelled{
  cursor: no-drop;
  background-color: rgb(128, 124, 124);
  color: white;
  height: 30px;
  font-size: 8px;
} 

.status-paid {
  cursor: no-drop;
  background-color: #a3cfa3;
  color: black;
  height: 30px;
  font-size: 10px;
} 

.dashboard-stats {
  .card-stats {
    .row {
      min-height: 80px;
    }
  }
}

.card-company {
  height: 152px;
  min-height: 152px;

  .company-icon {
    i {
      font-size: 40px;
    }

    img {
      // max-height: 80px;
      max-width: 270px;
      border-radius: 7px;
    }
  }
}

.card {
  &-header {
    &-with-button {
      align-items: center;
    }
  }

  .content {
    padding: 15px 15px 10px 15px;
  }
}

.dashboard-picker-title {
  display: inline-block;
  margin: 15px 20px;
}

.employee-table {
  .rt-tbody {
    .rt-tr-group {
      min-height: auto;
      max-height: 100%;
      margin-top: 10px;
      position: relative;

      .rt-tr {
        height: 100%;
        max-height: 100%;

        .rt-td {
          font-weight: 600;
          font-size: 14px;
          line-height: 19px;
          letter-spacing: -0.6px;
          color: #34525C;
        }

        &.-even {
          background-color: #ffffff !important;
        }
      }
    }
  }

  &__user {
    display: flex;
    align-items: center;
    padding-left: 20px;

    i {
      min-height: 50px;
      min-width: 50px;
      max-height: 50px;
      max-width: 50px;
      background-position: center;
      -webkit-background-size: cover;
      background-size: cover;
      background-color: gray;
      border-radius: 25px;
      margin-right: 10px;
    }

    div {
      max-width: calc(100% - 50px);

      h4 {
        margin: 0;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        letter-spacing: 0.404255px;
        color: #373737;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: left;
      }

      a {
        font-size: 13px;
        line-height: 18px;
        letter-spacing: 0.404255px;
        color: #979797;
        white-space: nowrap;
        overflow: hidden;
        display: block;
        width: 100%;
        text-align: left;
        text-overflow: ellipsis;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    &:after {
      content: '';
      height: 100%;
      width: 6px;
      background-color: red;
      top: 0;
      left: 0;
      position: absolute;
    }

    &_work {
      &:after {
        background-color: #5FCD64;
      }
    }

    &_no_work {
      &:after {
        background-color: #FFD037;
      }
    }

    &_absent,
    &_vacation {
      &:after {
        background-color: #FF1E50;
      }

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(255, 30, 80, 0.05);
      }
    }
  }

  &__status {
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: -0.6px;
    color: #5FCD64;
    text-transform: uppercase;
    float: right;

    &_work {
      color: #5FCD64;
    }

    &_no_work {
      color: #FFD037;
    }

    &_absent,
    &_vacation {
      color: #FF1E50;
    }
  }

  &__start {
    display: inline-flex;
    flex-direction: column;
    min-width: 65%;

    span {
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      letter-spacing: -0.6px;
    }
  }
}

.primary-layout {
  background-color: #ffffff;

  &__conetnt {
    min-height: 100vh;
    font-family: "Open Sans", "Roboto", "Helvetica Neue", sans-serif;
  }

  &__conn-err {
    background-color: red;
    padding: 20px;
    text-align: center;
  }
}

.horizontal-scroll_wrap {
  width: 100%;
}

.horizontal-scroll {
  width: 100%;
}

.lp-main {
  padding: 203px 80px 250px 150px;
  background-image: url('../../assets/img/lg/lg_main_bg.svg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  &_black {
    background-color: #3F3D56;

    a {
      color: #ffffff;
      text-decoration: underline;
      transition: 0.3s ease-in-out;

      &:hover {
        opacity: 0.6;
      }
    }
  }

  &__wrapper {
    position: relative;
    max-width: 1400px;
    width: 100%;
    margin: 0 auto;
  }

  &__content {
    width: 43%;
    position: relative;
    z-index: 2;

    h1 {
      font-weight: bold;
      font-size: 22px;
      line-height: 27px;
      letter-spacing: 0.444445px;
      color: rgba(255, 255, 255, 0.85);
      margin: 0 0 14px 0;
    }

    p {
      font-size: 14px;
      line-height: 22px;
      letter-spacing: 0.444445px;
      color: #FFFFFF;
    }

    &-buttons {
      display: flex;
      margin-top: 43px;

      .app-link:first-child {
        margin-right: 27px;
      }
    }
  }

  &__extra-content {
    margin-bottom: 200px;
    color: #ffffff;
  }

  &__img {
    top: -34%;
    left: 40%;
    max-width: 1000px;
    width: 50%;
    position: absolute;
    z-index: 1;

    img {
      width: 100%;
      height: auto;
    }
  }
}

.main-wrapper {
  padding: 30px 15px;
  min-height: calc(100vh - 123px);
}

.lp-section {
  padding-top: 50px;
  padding-left: 144px;
  padding-right: 144px;

  &_contacts {
    width: 100%;
    padding: 0;
    text-align: center;
  }

  &_features {
    width: 100%;
    max-width: 1600px;
    margin: 0 auto;
    box-sizing: border-box;
  }

  &__title {
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    letter-spacing: 0.444445px;
    color: rgba(0, 0, 0, 0.53);
    margin: 0;
    text-transform: uppercase;
  }

  &__sub-title {
    font-weight: 600;
    font-size: 36px;
    line-height: 44px;
    text-align: center;
    letter-spacing: 0.444445px;
    color: #343434;
    margin: 0 0 12px 0;
  }

  &__desc {
    display: inline-block;
    font-size: 14px;
    line-height: 18px;
    max-width: 600px;
    text-align: center;
    letter-spacing: 0.444445px;
    color: rgba(0, 0, 0, 0.53);
  }
}

.lp-features {
  display: flex;
  flex-wrap: wrap;
  padding-top: 30px;
  padding-bottom: 30px;

  &__item {
    padding: 30px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    width: 25%;
    display: flex;
    flex-direction: column;
    align-items: center;

    &-img {
      min-height: 73px;
      display: flex;
      margin-bottom: 10px;

      img {
        margin-top: auto;
        width: 91px;
        height: auto;
      }
    }

    h3 {
      font-weight: bold;
      font-size: 20px;
      line-height: 26px;
      text-align: center;
      letter-spacing: 0.444445px;
      color: #744098;
      margin: 0 0 9px 0;
    }

    span {
      font-size: 14px;
      line-height: 18px;
      text-align: center;
      letter-spacing: 0.444445px;
      color: #979797;
    }
  }
}

.lp-ability {
  background-color: rgba(247, 247, 247, 0.63);

  &__wrapper {
    padding: 76px 124px 156px 57px;
    display: flex;
    max-width: 1600px;
    margin: 0 auto;
  }

  &__img {
    img {
      height: auto;
      width: 485px;
    }
  }

  &__list {
    padding: 0 16px;

    .lp-ability-item:last-child {
      margin-bottom: 0;
    }
  }

  &-item {
    display: flex;
    align-items: flex-start;
    margin-bottom: 56px;

    &__img {
      background: linear-gradient(123.99deg, rgba(116, 64, 152, 0.25) 21.24%, rgba(255, 30, 80, 0.25) 90.58%);
      min-width: 82px;
      max-width: 82px;
      min-height: 82px;
      max-height: 82px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
    }

    &__content {
      padding-left: 23px;

      h4 {
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        letter-spacing: 0.444445px;
        color: #744098;
        margin: 0 0 6px 0;
      }

      p {
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0.444445px;
        color: #979797;
        margin: 0;
      }
    }
  }
}

.lp-app-for {
  padding: 64px 156px;
  max-width: 1600px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;

  &__item {
    padding: 12px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    min-height: 255px;

    //max-height: 255px;
    img {
      width: 100%;
      height: auto;
    }

    &_25 {
      min-width: 25%;
      max-width: 25%;
    }

    &_50 {
      min-width: 50%;
      max-width: 50%;
    }

    &_75 {
      min-width: 75%;
      max-width: 75%;
    }

    &_100 {
      min-width: 100%;
      max-width: 100%;
    }
  }

  &-main {
    padding: 35px 62px;
    /*min-height: 280px;*/
    background: linear-gradient(90.68deg, #744098 0.29%, #600461 99.83%);

    h4 {
      font-size: 26px;
      line-height: 31px;
      letter-spacing: 0.444445px;
      color: #FFFFFF;
      margin: 0 0 52px 0;
    }

    &__content {
      span {
        font-size: 18px;
        line-height: 23px;
        letter-spacing: 0.444445px;
        color: rgba(255, 255, 255, 0.57);
      }

      img {
        float: right;
        width: 29%;
        margin-top: -8%;
        min-width: 100px;
      }
    }
  }
}

.lp-contacts {
  display: flex;
  width: 100%;
  margin-top: 45px;
  padding-bottom: 43px;

  &__img {
    width: 50%;
    background-color: #F2F2F2;
    padding: 81px 24px 53px;
    display: flex;

    img {
      margin-left: auto;
    }
  }

  &__form {
    width: 50%;
    padding-left: 24px;
    padding-right: 24px;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    &-input {
      width: 100%;
      margin-bottom: 7px;

      &_s {
        width: calc(50% - 6px);
      }
    }

    &-footer {
      margin-top: 10px;
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;

      div:first-child {
        margin-right: 10px;
        font-size: 13px;
        line-height: 18px;
        letter-spacing: 0.7px;
        color: #979797;
      }
    }
  }
}

.lp-footer {
  width: 100%;
  background-color: #3F3D56;
  padding-top: 51px;
  display: flex;
  flex-direction: column;

  h3 {
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    letter-spacing: 0.444445px;
    color: #FFFFFF;
    margin: 0 0 34px 0;
  }

  &__main {
    margin: 0 auto;
    padding: 0 20px 68px 20px;
    display: inline-flex;
    flex-direction: column;

    &_row {
      display: flex;
      justify-content: space-between;
      margin-bottom: 34px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    a {
      font-size: 18px;
      line-height: 121%;
      letter-spacing: 0.444445px;
      color: rgba(255, 255, 255, 0.6);
      transition: 0.3s ease-in-out;
      display: inline-flex;
      align-items: center;
      text-decoration: none;

      img {
        max-width: 20px;
        margin-right: 10px;
        transition: 0.3s ease-in-out;
        opacity: 0.46;
      }

      &:hover {
        color: #ffffff;

        img {
          opacity: 1;
        }
      }
    }
  }

  &__bottom {
    border-top: 1px solid rgba(255, 255, 255, 0.17);

    &-wrapper {
      max-width: 1400px;
      margin: 0 auto;
      padding: 20px 50px;
      align-content: center;
      width: 100%;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
    }

    &-logo {
      img {}
    }

    &-download {
      color: rgba(255, 255, 255, 0.6);
      font-size: 16px;
      line-height: 17px;
      padding-top: 12px;
      text-decoration: underline;

      &:hover {
        color: white;
        text-decoration: underline;
      }
    }

    &-texts {
      display: flex;
      align-items: center;

      span {
        font-size: 14px;
        line-height: 17px;
        letter-spacing: 0.444445px;
        color: rgba(255, 255, 255, 0.6);
        border-right: 1px solid rgba(255, 255, 255, 0.17);
        padding: 0 20px;

        &:last-child {
          border-right: none;
        }
      }
    }

    &-social {
      a {
        margin-right: 25px;
        opacity: 0.46;
        transition: 0.3s ease-in-out;

        &:last-child {
          margin-right: 0;
        }

        img {
          max-width: 40px;
        }

        &:hover {
          opacity: 1;
        }
      }
    }
  }
}

.gradient_button {
  outline: none !important;
  border: none !important;
  background: linear-gradient(90deg, #744098 1.39%, #FF1E50 60.14%, #744098 98.61%);
  border-radius: 4px;
  padding: 13px 29px;
  line-height: 19px;
  letter-spacing: 0.718136px;
  color: #FFFFFF;
}

.app-link {
  border: none !important;
  outline: none !important;
  background-color: transparent;
  max-width: 157px;

  img {
    width: 100%;
  }
}

.login-page,
.register-page,
.forgot-page {
  padding-top: 120px;
  min-height: 100vh;
  background: #754199;

  .header-text {
    padding-top: 0;
  }

  .card {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

.lp-primary-button {
  font-weight: 600;
  font-size: 16px;
  line-height: 16px !important;
  letter-spacing: 0.45px;
  color: #FFFFFF;
  padding: 10px 24px;
  border: 1px solid #FFFFFF !important;
  box-sizing: border-box;
  border-radius: 4px;
  opacity: 1 !important;

  i {
    font-size: 12px;
    margin-right: 10px;
  }

  &:hover {
    opacity: 0.8 !important;
  }
}

.lp-secondary-button {
  font-weight: 600;
  font-size: 16px;
  line-height: 16px !important;
  letter-spacing: 0.45px;
  color: #FFFFFF;
  padding: 10px 24px;
  border: 1px solid #744098 !important;
  background-color: #744098 !important;
  box-sizing: border-box;
  border-radius: 4px;
  opacity: 1 !important;
  transition: 0.3s ease-in-out;

  i {
    font-size: 12px;
    margin-right: 10px;
  }

  &:hover {
    opacity: 0.8 !important;
  }
}

.lp-header {
  .navbar-nav {
    &>li>a {
      margin: 10px !important;
    }
  }

  .lp-text-btn {
    padding: 10px 5px;
    float: left;
  }

  #basic-nav-dropdown-1 {
    padding: 0;
    margin: 0 !important;
    opacity: 1;
  }
}

.nav-open .lp-header.navbar .container,
.nav-open .main-panel,
.nav-open .wrapper-full-page {
  transform: translate3d(-100%, 0, 0);
}

.nav-open .lp-header {
  background-color: #447df7;
}

.lp-text-btn {
  outline: none !important;
  border: none;
  background-color: transparent;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  cursor: pointer;
  letter-spacing: 0.444445px;
  color: #FFFFFF;
  margin: 10px;

  i {
    margin-right: 5px;
    font-size: 14px;
  }
}

.sidebar-user.user {
  width: 100%;

  a {
    display: flex;
    align-items: stretch;
    box-sizing: border-box;

    img.photo {
      margin-left: 0;
      min-width: 34px;
      min-height: 34px;
    }

    p {
      flex-grow: 1;
      line-height: 34px;
      opacity: 1;
      display: block;
      position: relative;
    }
  }
}

@media screen and (max-width: 998px) {
  .lp-header {
    .nav-link.navbar-brand {
      display: inline-flex;
      padding: 0 20px;
    }

    .navbar-nav>li>a {
      margin: 0 5px !important;
    }

    .nav-link {

      &.lp-primary-button,
      &.lp-secondary-button {
        margin: 5px !important;
      }
    }

    .dropdown {
      .lp-text-btn {
        width: 100%;
        text-align: center;
      }
    }

    .lp-text-btn {
      margin: 0 5px;
    }

    .dropdown-menu:after,
    .dropdown-menu:before {
      display: none !important;
    }

    .dropdown-menu li {
      display: inline-flex;
      justify-content: center;
      width: 100%;

      a {
        padding: 10px 5px !important;
      }
    }

    .navbar-nav {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    // .navbar-collapse{
    //   margin-top: 10px;
    // }
    // .navbar-collapse{
    //   border-bottom: 1px solid #ffffff;
    //   border-top: 1px solid #ffffff;
    // }
    .navbar-collapse {
      display: none !important;
    }
  }

  .lp-main {
    padding: 100px 20px;

    &__content {
      width: 100%;
      position: relative;
      z-index: 2;

      &-buttons {
        justify-content: center;
      }
    }

    &__img {
      display: none;
    }
  }

  .lp-section {
    padding-left: 50px;
    padding-right: 50px;
  }

  .lp-features {
    &__item {
      padding: 20px;
      width: 50%;
    }
  }

  .lp-ability {
    &__wrapper {
      padding: 50px;
    }

    &__img {
      display: none;
    }
  }

  .lp-app-for {
    padding: 50px 30px;

    &__item {
      &_s_100 {
        min-width: 100%;
        max-width: 100%;
      }

      &_s_75 {
        min-width: 75%;
        max-width: 75%;
      }

      &_s_50 {
        min-width: 50%;
        max-width: 50%;
      }
    }

    &-main {
      padding: 30px;

      h4 {
        margin: 0 0 20px 0;
      }

      &__content {
        img {
          display: none;
        }
      }
    }
  }

  .lp-contacts {
    margin-top: 25px;
    padding-bottom: 43px;

    &__img {
      display: none;
    }

    &__form {
      width: 100%;
    }
  }

  .lp-footer {
    &__bottom {
      &-wrapper {
        padding: 20px 30px;
        flex-direction: column;
        align-items: center;
      }

      &-texts {
        flex-direction: column;

        span {
          margin-top: 20px;
          border-right: none;
        }
      }

      &-social {
        margin-top: 20px;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .lp-section {
    padding-top: 30px;
    padding-left: 20px;
    padding-right: 20px;

    &__sub-title {
      font-size: 30px;
      line-height: 40px;
    }
  }

  .lp-features {
    &__item {
      padding: 10px;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  .lp-ability {
    &__wrapper {
      padding: 30px 20px;
    }

    &-item {
      margin-bottom: 30px;
      flex-direction: column;
      align-items: center;

      &__img {
        margin-bottom: 15px;
      }

      &__content {
        padding-left: 0;
        width: 100%;

        h4 {
          text-align: center;
        }

        p {
          text-align: center;
        }
      }
    }
  }

  .lp-app-for {
    padding: 20px 10px;

    &__item {
      padding: 6px;
    }

    &-main {
      padding: 20px 15px;
      min-height: auto;

      h4 {
        font-size: 20px;
        line-height: 25px;
        font-weight: 600;
        margin-bottom: 10px;
      }

      &__content {
        span {
          font-size: 14px;
          line-height: 18px;
        }
      }
    }
  }

  .lp-contacts {
    &__form {
      &-input {
        &_s {
          width: 100%;
        }
      }
    }
  }

  .lp-footer {
    h3 {
      margin: 0 0 15px 0;
    }

    &__main {
      padding: 0 20px 10px 20px;

      &_row {
        flex-direction: column;
        margin-bottom: 0;
      }

      a {
        font-size: 16px;
        margin-bottom: 15px;
      }
    }

    &__bottom {
      &-texts {
        span {
          text-align: center;
        }
      }
    }
  }
}

.leaflet-container {
  height: 100%;
  width: 100%;
}

.dropdown-menu-right .dropdown-menu:before {
  left: inherit;
  right: 12px;
}

.dropdown-menu-right .dropdown-menu:after {
  left: inherit;
  right: 12px;
}

.gps-info-form {
  margin-top: 20px;
  width: 100%;
  // display: flex;
  flex-wrap: wrap;

  .timelog-grid-gps-item a {
    cursor: pointer;
  }

  &__title {
    width: 100%;
    text-align: left;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 18px;
    font-family: 'Exo 2', sans-serif;
    line-height: 22px;
    letter-spacing: 0.40px;
    color: #2550AC;
  }

  &__item {
    display: inline-flex;
    flex-basis: 1px;
    flex-grow: 1;
    padding: 20px;
    box-sizing: border-box;
    border-right: 1px solid rgba(0, 0, 0, 0.09);
    // background-color: #F1F5F8;
    border-left: 0;

    &:last-child {
      border-right: 0;
    }

    &-label {
      font-weight: 600;
      font-size: 16px;
      line-height: 40px; //modified -> set to 22px for previous style
      letter-spacing: 0.75px;
      padding-right: 10px;
      flex-grow: 1;
    }

    &-value {
      display: inline-flex;
      align-items: center;
      text-transform: capitalize;
      font-weight: 600;
      font-size: 14px;
      letter-spacing: 0.75px;
      text-decoration: none;

      i {
        margin-right: 5px;
        font-weight: 900;
        font-size: 26px;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .gps-info-form {
    &__item {
      border: 1px solid rgba(0, 0, 0, 0.09) !important;
    }
  }
}

@media (min-width:1900px) {
  .flex-card {
    border-radius: 20px;
    background-color: #ffffff;
    margin-bottom: 30px;
    border: 1px solid rgba(0, 0, 0, 0.125);
    overflow: hidden;
    padding: 10px;
    min-width: 14.5rem;
    max-width: 24rem;
    min-height: 7rem;
    max-height: 18rem;
    height: 7.5rem;
    display: flex;
    flex-direction: column;
  }
}

.gps-info-on-list {
  cursor: pointer;
  width: 100%;
  border-radius: 6px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;

  // &:hover {
  //   background: white;
  // }
  span {
    font-weight: $font-weight-bold;
  }

  &-draft {
    background-color: $color-gray;
  }

  &-manual {
    background-color: $light-blue;
  }

  &-automatic {
    background-color: $light-blue;
  }

  &-verified {
    background-color: $light-green;
  }

  &-system {
    background-color: $color-dark-gray;
  }

  &-stamped {
    background-color: $light-red;
  }
}

.gps-info-on-list-status {
  cursor: no-drop;
  border-radius: 6px;
  height: 28px;
  display: flex !important;
  align-items: center;
  justify-content: center;

  &-system {
    background-color: $color-dark-gray;
  }

  &-manual {
    background-color: $light-blue;
  }
}


.notification-button-active {
  color: #fb404b !important;
}

.recipient-count-badge {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #4c83f7;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  color: white;
}

// Confirm Alert Customization
.react-confirm-alert-body_custom {
  width: auto;
  max-width: 600px;
  border-radius: 60px;
}

.react-confirm-alert-body {
  border-radius: 60px;
  color: #4B4D51;
  min-width: 500px;
  word-wrap: break-word;
}

.react-confirm-alert-button-group {
  display: flex;
  justify-content: end;

  button {
    background: #2550AC;
    border-radius: 22px;
    padding: 1.2em 3em;
  }

  button:nth-child(2) {
    background: #CDD6EA;
    color: #2550AC;

  }

}

.react-confirm-alert-body h1 {
  color: #2550AC;
}

.custom-confirm-box {
  max-width: 800px;
  background-color: #fff;
  border-radius: 4px;
  padding: 20px 15px 10px 15px;
  border: 1px solid rgba(0, 0, 0, 0.125);

  &__title {
    width: 100%;
    display: inline-block;
    font-size: 26px;
    font-weight: 400;
    letter-spacing: 0.45px;
    color: #818181;
    margin: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding: 10px 5px;
  }

  &__desc {
    font-size: 16px;
    color: #818181;
    padding: 20px 5px;
    display: inline-block;
  }

  &__buttons {
    display: flex;
    flex-wrap: wrap;
    padding: 5px 0;
  }

  &__button {
    padding: 5px;
  }
}

.react-datepicker-popper {
  z-index: 3;
}

.font-weight-bold {

  .checkbox label,
  .radio label {
    font-weight: bold;
  }
}

.checkbox.checkbox-inline {
  margin-top: 0;
}

.managers-widget {
  .react-select__control {
    border-radius: 10px;
    background: #F9FAF5;
    height: 40px;
    border: 0;
    font-size: 14px;

    .react-select__value-container {
      flex-wrap: nowrap !important;
      font-weight: 600;
    }

    .react-select__multi-value {
      min-width: 70px;
    }
  }
}

.managers-widget {
  .react-select__control--is-disabled {
    background: #9398AE;

    .react-select__value-container .react-select__single-value {
      color: #fff;
    }
  }
}

//this is a dialog pop-up, so should be always on top//
.react-confirm-alert-overlay {
  z-index: 9999;
}

// new style for modal
.modal-content {
  border-radius: 30px;
  overflow: hidden;
}

.modal_revised {
  .modal-content {
    border-radius: 30px 0 0 30px !important;
    border: 0 transparent;

    .modal-body {
      padding: 0 15px 0 40px;
      overflow-y: scroll;
    }
  }
}

.modal-header {
  background: #2550AC;
  padding: 0.8rem 1rem;
  color: white;
  font-size: 18px;
  font-weight: 600;
  justify-content: space-between;

  &__revised {
    background: white;
    color: #2550AC;
    border: 0;
    justify-content: start;

    &__closebtn {
      border: none;
      width: 33px;
      height: 33px;
      border-radius: 50%;
      font-weight: 600;
      background: #D9D9D9;
      margin-right: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  }
}

.login_modal .modal-content {
  border-radius: 0.5rem;
  overflow: visible;
}

.signup_modal .modal-content {
  border-radius: 0.5rem;
  overflow: visible;
}

.login_modal .modal-header {
  border-top-left-radius: calc(0.5rem - 1px);
  border-top-right-radius: calc(0.5rem - 1px);
  background: transparent;
  padding: 1rem 1rem;
  color: black;
  border-bottom: none;
}

.signup_modal .modal-header {
  border-top-left-radius: calc(0.5rem - 1px);
  border-top-right-radius: calc(0.5rem - 1px);
  background: transparent;
  padding: 1rem 1rem;
  color: black;
  border-bottom: none;
}

.custom-modal-title {
  margin: auto;
  font-weight: 600;
}


.dark-backdrop {
  &.show {
    opacity: 1;
    background-color: #333;
    -webkit-transition: background-color 500ms ease-in;
    -moz-transition: background-color 500ms ease-in;
    transition: background-color .4s ease-in;
  }
}

.tablink {
  background-color: #EDF0F6;
  color: #2550AC;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  font-weight: bolder;
  padding: 14px 7px;
  font-size: 17px;

  &__right {
    min-width: 33.33%;
    border-radius: 0px 14px 14px 0px;
  }

  &__right-doc {
    width: 50%;
    border-radius: 0px 14px 14px 0px;
  }

  &__left {
    min-width: 33.33%;
    border-radius: 14px 0px 0px 14px;
  }

  &__left-doc {
    width: 50%;
    border-radius: 14px 0px 0px 14px;
  }

  &__center {
    min-width: 33.33%;
  }

  &__active-right {
    background-color: #2550AC;
    color: white;
    min-width: 33.33%;
    border-radius: 0px 14px 14px 0px;
  }

  &__active-right-doc {
    background-color: #2550AC;
    color: white;
    min-width: 50%;
    border-radius: 0px 14px 14px 0px
  }

  &__active-left {
    background-color: #2550AC;
    color: white;
    min-width: 33.33%;
    border-radius: 14px 0px 0px 14px;
  }

  &__active-left-doc {
    background-color: #2550AC;
    color: white;
    min-width: 50%;
    border-radius: 14px 0px 0px 14px;
  }

  &__active-center {
    min-width: 33.33%;
    background-color: #2550AC;
    color: white;
  }
}

.tabContainer {
  display: block;
}

.checklist-sub-element-modal .modal-content {
  width: 630px;
}

@media (max-width: 520px) {
  .tabContainer {
    display: flex;
    flex-direction: column;
  }

  .tablink {
    border-radius: 10px !important;
    margin: 0.35rem !important;
  }

  .tablink__active-left {
    border-radius: 10px !important;
    margin: 0.35rem !important;
  }

  .tablink__active-right {
    border-radius: 10px !important;
    margin: 0.35rem !important;
  }

  .tablink__active-center {
    border-radius: 10px !important;
    margin: 0.35rem !important;
  }
}

.tablink:hover {
  background-color: #2550AC;
  color: white;
}

.tablink:active {
  background-color: #2550AC;
  color: white;
}

.timelog-tip-entry {
  .input-block {
    padding: 0;

    &__base-input {
      width: 115px;
      height: 38px;
      text-align: center;
    }
  }
}

.gps_coordinate_widget {
  display: flex;
  align-items: center;

  .gps_check_icon_wrapper {
    right: 2px;
    bottom: -1px;
  }

  i {
    color: darkgreen;
    line-height: 40px;
    font-size: 20px;
    font-weight: 600;
  }

  a {
    font-size: 12px;
    font-weight: 900;
    color: #2550AC !important;
  }
}

.cursor-no-drop {
  cursor: no-drop !important;
}

.border-top-fat-line {
  border-top: 3px solid #F1F1F1;
}

.contract-loading-modal {
  .modal-dialog {
    top: 25%;
  }
}

.text-primary-color {
  color: #2550ac;
}

.custom-padding-timelog-widget {
  padding: 3px 4px;
}

.custom-padding-schedule-indicator {
  padding: 1px 2px;
}

@import './responsive.scss';