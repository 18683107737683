.rectangle-card{
    min-width: 14.3rem !important;
    max-width: 18rem;
    min-height: 7rem;
    max-height: 18rem;
    border-radius: 20px!important;
    margin-bottom: 1rem!important;
}

.border-789BD0{
    border: 1px solid #789BD0 !important;
}

.border-none{
    border: none !important;
}

.rectangle-card .card-body{
    display: flex;
    align-items: center;
    justify-content: center;
}

.rectangle-card-bg-white{
    background-color: white!important;
}
.rectangle-card-bg-FFF6E6{
    background-color: #FFF6E6!important;
    border: none !important;
}
.rectangle-card-bg-D5DDF2{
    background-color: #D5DDF2!important;
    border: none !important;
}
.rectangle-card-bg-DFF7E9{
    background-color: #DFF7E9!important;
    border: none !important;
}
.rectangle-card-bg-F6DFDF{
    background-color: #F6DFDF!important;
    border: none !important;
}

.rectangle-card-bg-C3E7C3{
    background-color: #FAFAFA !important;
}

.rectangle-card-bg-F3DDDD{
    background-color: #FAFAFA !important;
}

.rectangle-card-bg-E1E1E1{
    background-color: #FAFAFA !important;
}

.rectangle-card-bg-ffa534{
    /* background-color: #ffa534 !important; */
}

.rectangle-card-bg-EFF1E4{
    background-color: #FAFAFA !important;
}

.rectangle-card-icon-bg-white{
    background-color: #fff !important;
}

.rectangle-card-icon-bg-FFE7BD{
    background-color: #FFE7BD  !important;
}

.rectangle-card-icon-bg-C2EDE9{
    background-color: #C2EDE9  !important;
}

.rectangle-card-icon-bg-FEB8B8{
    background-color: #FEB8B8 !important;
}

.rectangle-card-icon{
    width: 70px;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}

.color-ffa534{
    color: #ffa534 !important;
}

.color-FEAA15{
    color: #FEAA15 !important;
}

.color-0A89FF{
    color: #0A89FF !important;
}

.color-FF8383{
    color: #FF8383 !important;
}

.color-2550AC{
    color: #2550AC !important;
}

.rectangle-card .text-header{
    font-size: 12px;
    font-weight: 600;
    color: #4E4E4E;
    letter-spacing: 0.06em;
}

.rectangle-card .text-body{
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 1px;
    color: #4E4E4E;
}

.rectangle-card .text-footer{
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1px;
    /* text-align: end; */
}

.card-bg-img{
    min-width: 15rem;
    max-width: 23rem;
    min-height: 7rem;
    max-height: 18rem;
    height: 7rem !important;
}

.custom-style-time-bank {
    min-width: 14rem; 
    max-width: 30rem;
    min-height: 3rem; 
    max-height: 8.8rem;
}

/* Media queries */
@media (min-width:1600px) {
    .rectangle-card{
        min-width: 14.5rem;
        max-width: 24rem;
        min-height: 7rem;
        max-height: 18rem;
        border-radius: 20px!important;
        margin-bottom: 1rem!important;
    }

    .card-bg-img{
        min-width: 15rem;
        max-width: 24rem;
        min-height: 7rem;
        max-height: 18rem;
        height: 7rem;
    }
}



