.circular-progress-bar-wrapper{
    min-width: 6.3rem;
    max-width: 10rem;
    min-height: 6.3rem;
    max-height: 10rem;
}
.circular-progress-bar-wrapper-time-bank{
    min-width: 3rem;
    max-width: 4rem;
    min-height: 3rem;
    max-height: 4rem;
}